<template>

<div class="main-container">
    
  
    <GrupoListForm v-if="isAuth"/>
    <Login  v-else/>

   
  </div>

    
</template>

<script>
import GrupoListForm from "../components/forms/Listas/GrupoListForm.vue" 
import Login from "./Login.vue"


export default {
    name:"UserList",
    components:{
        GrupoListForm,
        Login
    },
    
    computed:{
      
      isAuth(){
         if (this.$store.getters.getUserID != null){
           return true
         } else {
           return false
         }

       }

    }






}
</script>

<style scoped>
  .main-container{
    margin: 50px;
    min-height: 250px;
  }
</style>

