<template>
    <div>

      <h2>Acesse</h2>  

        <!--

           <Message :msg="msg" v-show="msg"/>
        -->
        
         <div v-show="msg" class="alert"  :class="tipoAlerta" role="alert">
           {{msg}}
        </div>

 
        <form id="login-form" @submit="loginUser($event)">
            
            <!--

            <div class="input-container">
                <label for="tipousuario">Tipo de Usuário</label>
                <select name="tipousuario" id="tipousuario" v-model="tipousuario">
                    <option value="">Escolha tipo de usuário</option>
                    <option value="admin">Admin</option>
                </select>
            </div>

            -->
            
            
            <div class="input-container">
                <label for="login">Login</label>
                <input type="text" id="login" name="login"  v-model="login" placeholder="Digite WhatsApp">
            </div>

            <div class="input-container">
                <label for="Senha">Senha</label>
                <input type="password" id="senha" name="senha"  v-model="senha" placeholder="Digite a senha">
            </div>

            <div class="input-container">
                <input type="submit" class="submit-btn" value="Acessar" >
            </div>

        </form>
       
       <h3>Já tem conta? Então acesse sua conta.</h3>
        
    </div>
</template>

<script>
//import Message from '../Message.vue'
import {urlAPI} from '../../config.js'





export default {
   
   

   name:'LoginForm', 
   components:{
       //Message
   },
   
   
   data(){
       return{
           usernameBasic:"BiosAuth",
           passwordBasic:"!P@SSword",
           
           tipoAlerta:null,
           login:null,
           senha:null,
           pathfile:null,
           userID:null,
           nivelUser:null,
           tipoUser:null,
           nomeapresentacao:null,
           idadmin:null,
           tokenadmin:null,
           publictoken:null,
           devicetoken:null,
           msg:null
       }
   },
   methods:{

       async loginUser(e){
           e.preventDefault()
           
           /**
            * 
            * 
            * this para acessar dados do data()
            * 'Authorization': 'Basic ' + btoa(`${username}:${password}`)
            * 
            * 
            * * */
           
            const data = {
             login:  this.login,
             senha: this.senha,
            

           }

           let dataJson = JSON.stringify(data)
           console.log(dataJson)
           
           
          
           try {
                const req = await fetch(urlAPI+'/login',{
                    method:"POST",
                    headers: {"content-type":"application/json"},
                    body:dataJson 
                })
           
                const res = await req.json()                
                
                console.log('Resposta: ',res)

                dataJson = JSON.stringify(res)

                console.log('dataJson',dataJson) 

                

                if (res.Result == "OK"){

                    this.tipoAlerta = "alert-success"

                    this.msg = res.Message

                   this.userID = res.id 

                   this.nivelUser = res.nivel

                   this.tipoUser = res.tipousuario

                   this.nomeapresentacao = res.nomeapresentacao

                   this.idadmin = res.idadmin

                   this.tokenadmin = res.tokenadmin

                   this.publictoken = res.public_token

                   this.devicetoken = res.device_token

                   //Call Action
                   //this.$store.dispatch('saveFile', this.msg)


                   //Call Mutation
                   this.$store.commit('registreUser', this.userID)
                   this.$store.commit('registreNivelUser', this.nivelUser)
                   this.$store.commit('registreTipoUser', this.tipoUser)
                   this.$store.commit('registreNomeApresentacao', this.nomeapresentacao)
                   this.$store.commit('registreTokenAdmin', this.tokenadmin)
                   this.$store.commit('registrePublicToken', this.publictoken)
                   this.$store.commit('registreDeviceToken', this.devicetoken)
                   
                   if (this.$store.getters.getUserNivel >= 2){
                       this.$store.commit('registreIdAdmin', this.idadmin)
                   }else{
                       this.$store.commit('registreIdAdmin', this.userID)
                   }
                   
                   console.log('<<<ID>>>',this.userID,'<<<AdminID>>>', this.$store.getters.getIdAdmin)
                  
                   console.log('ID',this.userID,'Admin', this.$store.getters.getTokenAdmin)
                   console.log('ID',this.userID,'tokenAdmin', this.tokenadmin)
    
                   console.log('<<<Public Token>>>', this.$store.getters.getPublicToken)
                   console.log('<<<Device Token>>>', this.$store.getters.getDeviceToken)

    

                } else {


                    this.tipoAlerta = "alert-warning"

                    this.msg = res.Message



                }

                /**
                  
                    if (dataJson.indexOf("OK") > 0){

                    this.msg = "Usuário inserido com sucesso"

                    
                    } else {

                        this.msg = "Falha ao enviar usuário"
                        
                        if(dataJson.indexOf("idx_email") > 0){
                            this.msg = "Email cadastrado anteriormente"
                        }


                    }
                  
                  
                  
                  
                  
                  
                 * ** */
                
                

                console.log(this.msg)
                
                

               
           
           } catch (error) {
                this.tipoAlerta = "alert-danger"

                this.msg = 'Ocorreu um erro ao processar informações' 
                console.log('Error: ',error);
           }   
            
           
           setTimeout(()=>this.msg = "", 3000) 

            /**
            
           const req = await fetch("http://172.107.45.205:9000/users/insert",{
               method:"POST",
               headers: {"content-type":"application/json"},
               body:dataJson 
           }).then(function(response){
               //this.msg = `Usuário salvo com sucesso`
               return response.json()
               console.log(response)
           }).catch(function(error){
               return error
           })
            
            
            ** */


          



            
            
            //console.log(dataJson)
            //console.log(res)
            //console.log(req)

           

           //const res = await req.json()   

           //Mostrar mensagem de retorno


           //limpar campo
           this.login = ""
           this.senha = ""
           this.pathfile = ""

           //console.log("salvando usuario", res)
       }

   },
   mounted(){
      
   }   
}
</script>

<style scoped>
    #login-form{
         max-width: 400px;
         margin: 0 auto;
    }

    .input-container{
         display: flex;
         flex-direction: column;
         margin-bottom: 20px;
    }

    label{
         font-weight: bold;
         margin-bottom: 15px;
         color: #FCBA03;
         padding: 5px 10px;
         border-left: 4px solid #FCBA03;
    }

    input, select{
         padding: 5px 10px;
         width: 300px;
    }

    .submit-btn{
         background: #fff;
         color: #FCBA03;
         font-weight: bold;
         border: 2px solid #222;
         padding: 10px;
         font-size: 16px;
         margin: 0 auto;
         cursor: pointer;
         transition: .5s;
    }

    .submit-btn:hover{
         background-color: transparent;
         color: #FCBA03;
    }


</style>