import { createStore } from 'vuex'

export default createStore({
  strict:true,
  
  state: {
    user:{
      userID:null,
      nivel:null,
      tipoUsuario:null,
      idadmin:null,
      nomeapresentacao:null,
      nomesistema:null,
      tokenadmin:null,
      publictoken:null,
      devicetoken:null

    }
    
    
  },
  
  getters: {
    getUserID: state => {
      console.log('->userID',state.user.userID)
      return state.user.userID
    },
    getUserNivel: state => {
      console.log('->userNivel',state.user.nivel)     
      return state.user.nivel
    },
    getUserTipo: state => {
      console.log('->userTipoUsuário',state.user.tipoUsuario)      
      return state.user.tipoUsuario
    },
    getIsAuth: state => {
      return state.user.userID != null ? true : false 
    },
    getIsMaster: state => {
      return state.user.tipoUsuario == 'MASTER' ? true : false
    },
    getIsAdmin: state => {
      return state.user.tipoUsuario == 'ADMIN' ? true : false
    },
    getIsColaborador: state => {
      return state.user.tipoUsuario == 'COLABORADOR' ? true : false
    },
    getIdAdmin: state => {
      console.log('idadmin',state.user.idadmin)
      return state.user.idadmin
    },
    getNomeApresentacao: state => {
      console.log('Apresentação',state.user.nomeapresentacao)
      return state.user.nomeapresentacao
    },
    getNomeSistema: state => {
      console.log('NomeSistema',state.user.nomesistema)
      return state.user.nomesistema
    },
    getTokenAdmin: state => {
      console.log('TokenAdmin',state.user.tokenadmin)
      return state.user.tokenadmin
    },
    getPublicToken: state => {
      console.log('PublicToken',state.user.publictoken)
      return state.user.publictoken
    },
    getDeviceToken: state => {
      console.log('DeviceToken',state.user.devicetoken)
      return state.user.devicetoken
    },

    
  },
  
  //Funções syncronas
  mutations: {
    
    saveFile(state,payload){
      state.user.userID = payload

    },

    registreUser(state, payload){

      state.user.userID = payload

      console.log('<ID>',state.user.userID)

    },

    registreNivelUser(state, payload){

      state.user.nivel = payload

      console.log('<Nivel>',state.user.nivel)

    },

    registreTipoUser(state, payload){

      state.user.tipoUsuario = payload

      console.log('<Tipo Usuário>',state.user.tipoUsuario)

    },

    registreIdAdmin(state, payload){

      state.user.idadmin = payload

      console.log('<Administrador>',state.user.idadmin)

    },

    registreNomeApresentacao(state, payload){

      state.user.nomeapresentacao = payload

      console.log('<Administrador>',state.user.nomeapresentacao)

    },

    registreNomeSistema(state, payload){

      state.user.nomesistema = payload

      console.log('<NomeSistema>',state.user.nomesistema)

    },

    registreTokenAdmin(state, payload){

      state.user.tokenadmin = payload

      console.log('<Token Admin>',state.user.tokenadmin)

    },

    registrePublicToken(state, payload){

      state.user.publictoken = payload

      console.log('<Public Token>',state.user.publictoken)

    },

    registreDeviceToken(state, payload){

      state.user.devicetoken = payload

      console.log('<Device Token>',state.user.devicetoken)

    },





  },
  
  //Funções asyncronas
  actions: {
    
    saveFile(context, payload){
      
      context.commit('saveFile', payload)
      
      console.log(payload)

    }


  },
  
  modules: {
  }

})
