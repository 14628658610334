<template>

  <div class="main-app">
    
      <div class="row">
      
          <Navbar :logo="logo_src" :alt="app_name" :user="this.$store.getters.getNomeApresentacao"/> 

          <div class="col-xs-3 col-md-2">
            <Sidebar />
          </div>
          <div class="col-xs-9 col-md-10">
              <router-view/> 
          </div>          
                    
          
          

          <Footer />
    
      </div>
    
  </div> 

</template>

<script>
   

    import Navbar from './components/Navbar.vue'
    import Sidebar from './components/Sidebar.vue'
    import Footer from './components/Footer.vue'
    

    export default {
        
        
        components:{
          Navbar,
          Sidebar,
          Footer
        },
        data(){
          return{
            /* TDOD: Buscar caminho da logo e nome do aplicativo via $store na base de dados */
            //logo_src:"/img/logo.png Burguer",
            logo_src:"/img/logobios.png",
            
            
            
            //app_name:"Apetitando"
            //app_name:"Prospectando"
            app_name:"Mercador"
          }
        }
    }
</script>

<style>
  * {
    font-family:Helvetica;
    padding:0;
    margin:0;
    box-sizing:border-box;
    
  } 

  h1{
    text-align: center;
    font-size: 42px;
    margin-bottom: 30px;
    color:#222
  }

 
</style>
