<template>
  <div class="main-home">

      <div class="main-container">
          <!-- Banner   <h1>Monte seu pedido</h1>  -->
          <Banner />

          <!-- Formulário -->   
      </div>



  </div>
  
</template>

<script>
   import Banner from '../components/Banner.vue'

export default {
  name: 'Home',
  components:{
    Banner
  }

}
</script>

<style scoped>
 
</style>


