/* https://stackoverflow.com/questions/69622067/how-to-remove-from-the-url-vue*/


//import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import GrupoList from '../views/GrupoList.vue'



let routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/grupo', //Caminho
    name: 'grupolist',  //View
  
    component: GrupoList //Import da View
  },



  {
    path: '/inscreva_se', //Caminho
    name: 'inscreva_se',  //View
  
    component: () => import('../views/Inscreva_se.vue') //Import da View
  },

  
  
  
  {
    path: '/login', //Caminho
    name: 'login',  //View
  
    component: () => import('../views/Login.vue') //Import da View
  },


  {
    path: '/userlist', //Caminho
    name: 'UserList',  //View
  
    component: () => import('../views/UserList.vue') //Import da View
  },

  {
    path: '/produtolist', //Caminho
    name: 'produtoList',  //View
  
    component: () => import('../views/ProdutoList.vue') //Import da View
  },
  {
    path: '/prospeccao', //Caminho
    name: 'prospeccao',  //View
  
    component: () => import('../views/Prospeccao.vue') //Import da View
  },


  {
    path: '/clientelist', //Caminho
    name: 'clienteList',  //View
  
    component: () => import('../views/ClienteList.vue') //Import da View
  },



  {
    path: '/pedidos', //Caminho
    name: 'Pedidos',  //View
  
    component: () => import('../views/Pedidos.vue') //Import da View
  },


  {
    path: '/qrcode', //Caminho
    name: 'QrCode',  //View
  
    component: () => import('../views/QrCode.vue') //Import da View
  },
 
  {
    path: '/credenciaiswhatsapp', //Caminho
    name: '',  //View
  
    component: () => import('../views/Credenciais.vue') //Import da View
  },



]

const router = createRouter({
  //history: createWebHashHistory(),
  history:createWebHistory(), 
  routes
})

export default router

/*


  {
    path: '/administrativo', //Caminho
    name: 'Administrativo',  //View
  
    component: () => import('../views/Administrativo.vue') //Import da View
  },


{
    path: '/pedidos', //Caminho
    name: 'Pedidos',  //View
  
    component: () => import('../views/Pedidos.vue') //Import da View
  },
  {
    path: '/userlist', //Caminho
    name: 'UserList',  //View
  
    component: () => import('../views/UserList.vue') //Import da View
  },



*/

