//export const urlAPI  = "http://localhost:9005";
//export const urlAPI  = "http://108.181.223.254:9001"
//export const urlAPI  = "http://172.107.45.205:9000";
export const urlAPI  = "https://dados.biosapp.com.br"

export const APIBR_URL = 'https://cluster.apigratis.com/api/v2/whatsapp'
export const APIBR_SECRET_KEY = '8e2ad285-1e15-4d8c-8aea-c47cfc3b9836'
export const APIBR_AUTH = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3BsYXRhZm9ybWEuYXBpYnJhc2lsLmNvbS5ici9hdXRoL2xvZ2luIiwiaWF0IjoxNjg0NTE5MDg0LCJleHAiOjE3MTYwNTUwODQsIm5iZiI6MTY4NDUxOTA4NCwianRpIjoiQjJUYnJYRzU2ZGVvV2k3dSIsInN1YiI6IjI5NzAiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.6jEyXG0xOKwwCblNw9_OUYdO82isvygUmEcuCWKm2b4'

export const versao = "1.0.0.0";

export const tipoOp = Object.freeze({
  view:"view",
  create:"create",
  read:"read",
  update:"update",
  delete:"delete",
  
})

export default {
  urlAPI,
  versao,
  tipoOp, 

  APIBR_URL,
  APIBR_AUTH,
  APIBR_SECRET_KEY
}
