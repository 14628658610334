<template>
 
  <div class="nav"  v-if="this.$store.getters.getIsAuth && (this.$store.getters.getIsMaster || this.$store.getters.getIsAdmin )">

    <br />
    
    <!-- Administradores -->
    <router-link to="/userlist" >
        <button> <strong>Usuários</strong> </button>
    </router-link>


    <!-- Prospecção -->
    <div class="nav" v-if="this.$store.getters.getNomeSistema == 'Prospecção'">

      <router-link to="/produtolist" >
          <button>  <strong>Produtos</strong></button>
      </router-link>


    </div>

    <!--

    <router-link to="/pedidos" >
          <button> <strong>Pedidos</strong> </button>
    </router-link>


    -->
    

    <!-- Mercador -->

  </div>

  <div class="nav"  v-if="this.$store.getters.getIsAuth"> 

        <br />
        
        <!-- Prospecção -->
        <div class="nav" v-if="this.$store.getters.getNomeSistema == 'Prospecção'">

            <router-link to="/clientelist" >
              <button>  <strong>Clientes</strong></button>
            </router-link>


            <router-link to="/prospeccao" >
                <button> <strong>Prospecção</strong> </button>
            </router-link>

        </div>


        <!-- Mercador -->
        <div class="nav" v-if="this.$store.getters.getNomeSistema == 'Mercador'">

          <router-link to="/grupo" >
              <button>  <strong>Grupo</strong></button>
          </router-link>


        </div>


        <!--WhatsApp-->
        <router-link to="/qrcode" >
              <button>  <strong>QrCode</strong></button>
        </router-link>

        <router-link to="/credenciaiswhatsapp" >
           <button> <strong>Credenciais</strong> </button>
        </router-link>

        

  </div>









  <!--

        <router-link to="/" >
        <button> <strong>Desconectar</strong> </button>
    </router-link>
        
    <router-link to="/userlist" >Gerenciar</router-link>
    
    -->

</template>

<script>

export default {
  
  
  
  name:"Sidebar",
   components:{
     
   },
   

   methods:{

      reload(){
         
         location.reload(true)
        
         
      }

   },

   mounted(){
      
   },

   created(){
    
   },

   props:["logo","alt"]
}

</script >

<style scoped>
  #nav{
    background-color:white;
   
    padding: 15px 50px;
    
    justify-content: flex-end;
    align-items: center;
    
  }

  /* Colocando logo para esquerda */
  .nav #logo_url{
    margin:auto;
    margin-left: 0;
  }

  #logo{
    width: 40px;
    height: 40px;
  }

  .nav a{
    color: #FCBA03;
    text-decoration: none;
    margin:12px;
    transition: .5s;
  }

  .nav a:hover{
    color:#FFF
  }

  button{
    background-color:gray;
    margin:15px 15px auto;
    width:130px;
    height: 60px;

    
  }




</style>
