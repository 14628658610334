<template>
    <div id="footer">
        <!--Apetitando {{titulo_rodape}}--->
        <p>Bios Automação&copy; {{ new Date().getFullYear() }}</p>
        
    </div>
</template>

<script>
import {urlAPI} from '../config.js'

   export default{
       name:"Footer",

        components:{

        },
    
        data(){
            return{
                titulo_rodape:null,
                NomeSistema:null  

            } 

        },

        mounted(){
        
            console.log('Montando..')
        },

        created(){
            this.getConfig() 
            console.log('Criando..')
        },

        methods:{
        async getConfig(){
            /** TODO: Carreagar no $store para que chame esta rotina uma única vez*/

            console.log('Buscando...')

                try {

                    console.log('Iniciando busca...', urlAPI)
                    
                    const req = await fetch(`${urlAPI}/config/find/1`,{
                        method:"GET",
                        headers: {"content-type":"application/json"},
                        
                    })

                    console.log('Finalizando busca...', urlAPI)
                    const res = await req.json()
                    
                    this.titulo_rodape = res.titulo_rodape
                    
                    
                    this.NomeSistema = res.NomeSistema
                    this.$store.commit('registreNomeSistema', this.NomeSistema)

                    console.log('Titulo principal',this.titulo_rodape) 
                    console.log('Nome Sistema',res.NomeSistema )                 
                    
                    
                } catch (error) {
                    console.log("erro ao acessar configurações gerais", error)
                }



        }  


    }


}
</script>

<style scoped>
   #footer{
       height: 200px;
       background-color: #222;
       border-top: 4px solid #111;
       color: #fcba03;
       display:flex;
       align-items: center;
       justify-content: center;
       width: 100%;
   }
</style>
