<template>

       <div id="main-banner" >
           <!--Monte seu pedido {{titulo_principal}} <h1>Prospecção</h1>  -->
           <h1>{{this.$store.getters.getNomeSistema}}</h1>
           
       </div>
     
</template>

<script>
import {urlAPI} from '../config.js'


export default{
    

    name:"Banner",
    
    components:{

    },
    
    data(){
         return{
             titulo_principal:null,
             NomeSistema:null  

         } 

    },

    mounted(){
               
        console.log('Montando..')
    },

    created(){
        //this.getConfig() 
        console.log('Criando..')
    },

    methods:{
       async getConfig(){
           /** TODO: Carreagar no $store para que chame esta rotina uma única vez*/
           console.log('Buscando...')

            try {

                  console.log('Iniciando busca...', urlAPI)
                 
                 const req = await fetch(`${urlAPI}/config/find/1`,{
                    method:"GET",
                    headers: {"content-type":"application/json"},
                     
                })

                console.log('Finalizando busca...', urlAPI)
                const res = await req.json()
                
                this.titulo_principal = res.titulo_principal
                
                
                this.NomeSistema = res.NomeSistema
                this.$store.commit('registreNomeSistema', this.NomeSistema)
               
                console.log('Titulo principal',res.titulo_principal) 
                
                console.log('Nome Sistema',res.NomeSistema)
                
                //console.log('Nome Sistema',this.$store.getters.getNomeSistema) 
               
                 
                
            } catch (error) {
                console.log("erro ao acessar configurações gerais", error)
            }



       }  


    }
    
}


</script>

<style scoped>
   /* Estilo banner ... */
   
   #main-banner{
       /** TODO: Verificar como pegar o caminho da base de dados e configurar no background-image * */
       
       /* background-image:url('/img/burger.jpg');  
          background-image:url('/img/logobios.jpg');
        */
       
       
       background-position: 0 -250px;
       background-size: cover;
       height: 500px;
       display: flex;
       align-items: center;
       justify-content: flex-start;

      /**
      
        background-color:#222;  
        border-bottom: 4px solid #111;
        padding: 15px 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;  
      
      * */

    
        
       
   }

   #main-banner h1{
       color: #fff;
       text-align: center;
       font-size: 60px;
       background-color: #222;
       padding: 20px 40px;
   }
</style>


