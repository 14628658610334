//import dotenv from 'dotenv'


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//const dotenv = require('dotenv');

//Variável de ambiente
//dotenv.config()


// Import Bootstrap an BootstrapVue CSS files (order is important)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/js/bootstrap'

/**
 
import Config from './config'


//Carregando módulos
const session = require('express-session')
const flash = require('connect-flash')

App.use(Config)

//Sessão
App.use(session({

    secret:"e550d021f9916c02c0320738c0a58b82",
    resave:true,
    saveUninitialized:true
  
  
  
  }))
  
  App.use(flash())
  
  //Middleware Variável glogal
  App.use((req, res, next)=>{
        res.locals.success_msg = req.flash("success_msg")
        res.locals.error_msg = req.flash("error_msg")
        next()
  })
  



 * 
 */




//Criando App
createApp(App).use(store)
              .use(router)
              .mount('#app')






