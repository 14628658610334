<template>
  <div id="nav">
    <router-link to="/" id="logo_url">
        <img :src="logo" :alt="alt" id="logo">
    </router-link>  

    
    <router-link to="/">Home</router-link>
    <router-link to="/login" v-if="this.$store.getters.getIsAuth">{{user}}</router-link> 
       
    <router-link to="/inscreva_se" v-if="!this.$store.getters.getIsAuth">Inscreva-se</router-link>
    <router-link to="/login" v-if="!this.$store.getters.getIsAuth">Login</router-link>
    
    <!--

     <router-link to="/userlist" >Usuários</router-link>
    <router-link to="/produtolist" >Produtos</router-link>
    <router-link to="/pedidos" >Pedidos</router-link>
    <router-link to="/administrativo" >Administrativo</router-link>



    -->
    
    
  </div>


  <!--
        
    <router-link to="/userlist" >Gerenciar</router-link>
    
    -->

</template>

<script>

export default {
  
  
  
  name:"Navbar",
   components:{
     
   },
   data(){
     return{
        
        
     }
     
   },

   mounted(){
      
   },
   created(){
    
   },

   props:["logo","alt","user"]
}

</script >

<style scoped>
  #nav{
    background-color:#222;
    border-bottom: 4px solid #111;
    padding: 15px 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  /* Colocando logo para esquerda */
  #nav #logo_url{
    margin:auto;
    margin-left: 0;
  }

  #logo{
    width: 40px;
    height: 40px;
  }

  #nav a{
    color: #FCBA03;
    text-decoration: none;
    margin:12px;
    transition: .5s;
  }

  #nav a:hover{
    color:#FFF
  }




</style>
