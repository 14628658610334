<template>
  <div>

      <div class="main-container">
          
          <div class="container">
              <div class="row">
                 <div class="col-sm-4">
                   
                   
                   <LoginForm v-if="!this.$store.getters.getIsAuth"/>
                   <div v-else>

                        <center>

                            <h3>Olá {{this.$store.getters.getNomeApresentacao}}, você está conectado!</h3>
                            <br />
                            
                            
                            <p ><strong>Caso queira desconectar da sua conta acione o botão abaixo</strong></p>
                            <button @click="reload()">Desconectar</button>




                        </center>


                   </div>
                   
                   
                 </div>


              </div>

          </div>


          

          <!-- Formulário -->   
      </div>



  </div>
  
</template>

<script>
   import LoginForm from '../components/forms/LoginForm.vue'
  

export default {
  
  name: 'Home',

  components:{
    LoginForm
  },

  methods:{
      reload(){
        location.reload(true)
      }
  },




}
</script>


<style scoped>
 .main-container{
    margin: 50px;
    min-height: 250px;
  }

button{
      background-color:gray;
      margin:15px 15px auto;
      width:130px;
      height: 60px;
  }

</style>


